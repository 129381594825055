import styled from 'styled-components';

const PortalStyles = styled.div`
  .dashboard-navbar {
    height: 50px !important;
  }

  .revenue-card {
    position: relative;
    margin-bottom: 0;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }

  .revenue-card:hover {
    transform: translateY(-5px);
  }

  .card-title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #333;
  }

  .big-value {
    display: flex;
    align-items: center;
    text-align:center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .percentage-change {
    margin-left: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .percentage-change.increase {
    color: #4CAF50;
  }

  .percentage-change.decrease {
    color: #F44336;
  }

  .progress-bar {
    margin-top: 10px;
    height: 10px;
    border-radius: 5px;
  }

  .progress-bar.increase {
    background-color: #4CAF50;
  }

  .progress-bar.decrease {
    background-color: #F44336;
  }

  .progress-text {
    margin-top: 5px;
    font-size: 0.9rem;
    color: #666;
  }

  .period-labels {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
    text-align: center;
  }

  .period-label {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }

  .period-label.bold {
    font-weight: bold;
  }

  .revenue-period.selected-period {
    background-color: #f0f0f0;
    padding-bottom: 3px;
    padding-top: 3px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
  }

  .tab-label {
    text-align: center;
  }

  .tab-percentage {
    display: block;
    font-size: 0.9rem;
  }

  .tab-percentage.increase {
    color: #4CAF50;
  }

  .tab-percentage.decrease {
    color: #F44336;
  }

  .fetch-button {
    margin-top: 10px;
  }

  .filtered-revenue {
    margin-top: 10px;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    .tab-percentage {
      display: none;
    }
  }
`;

export default PortalStyles;
