import Portal from "../../components/Portal"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {doLogout} from "../Auth/operations";
import {
    doClearRevenue,
    doGetCOReportInformation,
    doGetDAYNNIGHTTotalRevenue, doGetOKReportInformation, doGetRevenue, doGetRevenueFilter,
    doGetSHAHVENTURESLLCTotalRevenue, doGetSOLOVENTURESCOTotalRevenue,
    doGetUNOVENTURESCOTotalRevenue, doGetUTReportInformation, doGetVIBEZVENTURESLLCTotalRevenue
} from "../Revenue/operations";
import {doClearUser} from "../User/operations";

const PortalContainer = props => {
    const {
        user, token, doLogout, history,
        daynnightRevenue,
        shahventuresllcRevenue,
        unoventurescoRevenue,
        soloventurescoRevenue,
        vibezventuresllcRevenue,
        odooUtahRevenue,
        odooColoradoRevenue,
        odooOklahomaRevenue,
        revenue,
        revenueFilter,
        doGetDAYNNIGHTTotalRevenue,
        doGetSHAHVENTURESLLCTotalRevenue,
        doGetUNOVENTURESCOTotalRevenue,
        doGetSOLOVENTURESCOTotalRevenue,
        doGetVIBEZVENTURESLLCTotalRevenue,
        doGetUTReportInformation,
        doGetCOReportInformation,
        doGetOKReportInformation,
        doGetRevenue,
        doGetRevenueFilter,
        doClearUser,
        doClearRevenue
    } = props;
    return <Portal
        user={user}
        token={token}
        history={history}
        doLogout={doLogout}
        daynnightRevenue={daynnightRevenue}
        shahventuresllcRevenue={shahventuresllcRevenue}
        unoventurescoRevenue={unoventurescoRevenue}
        soloventurescoRevenue={soloventurescoRevenue}
        vibezventuresllcRevenue={vibezventuresllcRevenue}
        odooUtahRevenue={odooUtahRevenue}
        odooColoradoRevenue={odooColoradoRevenue}
        odooOklahomaRevenue={odooOklahomaRevenue}
        revenue={revenue}
        revenueFilter={revenueFilter}
        doGetDAYNNIGHTTotalRevenue={doGetDAYNNIGHTTotalRevenue}
        doGetSHAHVENTURESLLCTotalRevenue={doGetSHAHVENTURESLLCTotalRevenue}
        doGetUNOVENTURESCOTotalRevenue={doGetUNOVENTURESCOTotalRevenue}
        doGetSOLOVENTURESCOTotalRevenue={doGetSOLOVENTURESCOTotalRevenue}
        doGetVIBEZVENTURESLLCTotalRevenue={doGetVIBEZVENTURESLLCTotalRevenue}
        doGetUTReportInformation={doGetUTReportInformation}
        doGetCOReportInformation={doGetCOReportInformation}
        doGetOKReportInformatio={doGetOKReportInformation}
        doGetRevenue={doGetRevenue}
        doGetRevenueFilter={doGetRevenueFilter}
        doClearUser={doClearUser}
        doClearRevenue={doClearRevenue}
    />;
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token,
    daynnightRevenue: state.revenueReducer.daynnightRevenue,
    shahventuresllcRevenue: state.revenueReducer.shahventuresllcRevenue,
    unoventurescoRevenue: state.revenueReducer.unoventurescoRevenue,
    soloventurescoRevenue: state.revenueReducer.soloventurescoRevenue,
    vibezventuresllcRevenue: state.revenueReducer.vibezventuresllcRevenue,
    odooUtahRevenue: state.revenueReducer.odooUtahRevenue,
    odooColoradoRevenue: state.revenueReducer.odooColoradoRevenue,
    odooOklahomaRevenue: state.revenueReducer.odooOklahomaRevenue,

    revenue: state.revenueReducer.revenue,
    revenueFilter: state.revenueReducer.revenueFilter
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            doLogout,

            doGetDAYNNIGHTTotalRevenue,
            doGetSHAHVENTURESLLCTotalRevenue,
            doGetUNOVENTURESCOTotalRevenue,
            doGetSOLOVENTURESCOTotalRevenue,
            doGetVIBEZVENTURESLLCTotalRevenue,
            doGetUTReportInformation,
            doGetCOReportInformation,
            doGetOKReportInformation,
            doGetRevenue,
            doGetRevenueFilter,
            doClearUser,
            doClearRevenue
        },
        dispatch,
    );

PortalContainer.propTypes = {}
export default connect(mapStateToProps, mapDispatchToProps)(PortalContainer);
