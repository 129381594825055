import './utils/polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Normalize } from 'styled-normalize';
import reportWebVitals from './reportWebVitals';
import { bindActionCreators } from 'redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

//REDUX
import { Provider, connect } from 'react-redux';
// import configureStore from './redux/store';
import store from './redux/store';
import Routes from './routes';
//STYLES
import './styles/fonts.css';
import GlobalStyles from './styles/GlobalStyles';

const Index = props => {
  // Bugsnag.start({
  //   apiKey: window.BUGSNAG_API,
  //   plugins: [new BugsnagPluginReact()],
  // });
  //
  // const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  return (
    <React.Fragment>
      {/*<ErrorBoundary>*/}
      <Normalize />
      <GlobalStyles />
      <Routes />
      {/*</ErrorBoundary>*/}
    </React.Fragment>
  );
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({});

/**
 * Main redux dispach functions
 * @param {*} dispatch redux dispatch
 * @returns {{}} state objects
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Index.propTypes = {};

const ConnectedIndex = connect(mapStateToProps, mapDispatchToProps)(Index);

let startApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <ConnectedIndex />
        </PersistGate>
      </Provider>
    </React.Fragment>,
  );
};

//STORE
// export const store = configureStore();

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
