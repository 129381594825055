// Import types
import { LIST_USER_REQUEST, READ_USER_REQUEST, UPDATE_USER_REQUEST, CREATE_USER_REQUEST, DELETE_USER_REQUEST, CLEAR_USER, UPDATE_DELETE_USER_REQUEST, CLEAR_UPDATE_USER } from './types';

// Export operations
/**
 * Dispatch for getting users
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetUsers = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_USER_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting specific user
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doGetUser = ({ token, id }) => ({
  type: READ_USER_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating user
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateUser = ({ token, payload }) => ({
  type: CREATE_USER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating user
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateUser = ({ token, id, payload }) => ({
  type: UPDATE_USER_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for update delete user
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateDeleteUser = ({ token, id, payload }) => ({
  type: UPDATE_DELETE_USER_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting specific user
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteUser = ({ token, id }) => ({
  type: DELETE_USER_REQUEST,
  token,
  id,
});

/**
 * Dispatch user to initial state
 * @return {Object} Dispatch
 */
export const doClearUpdateUser = () => ({
  type: CLEAR_UPDATE_USER,
});

/**
 * Dispatch user to initial state
 * @return {Object} Dispatch
 */
export const doClearUser = () => ({
  type: CLEAR_USER,
});
