// IMPORTS
// --------------------------------------------------------
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import {
  doLogin,
  doLoginReset,
  doForgotPassword,
  doPasswordReset,
  doForgotPasswordReset,
  doPasswordResetReset,
  doActivationReset,
} from './operations';

import Login from '../../views/Auth/Login';
import PasswordReset from '../../views/Auth/PasswordReset';
import Register from '../../views/Auth/Register';
import ForgotPassword from '../../views/Auth/ForgotPassword';
import Activation from '../../views/Auth/Activation';
import Unsubscribe from '../../views/Auth/Unsubscribe';
import { withRouter } from "react-router-dom";

// AUTH CONTAINER
// --------------------------------------------------------
/**
 * AuthContainer
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const AuthContainer = props => {
  const {
    match,
    history,
    login,
    register,
    forgotPassword,
    passwordReset,
    activation,
    doLogin,
    doRegister,
    doLoginReset,
    doRegisterReset,
    doForgotPassword,
    doPasswordReset,
    doActivationReset,
    doForgotPasswordReset,
    doPasswordResetReset,
  } = props;

  const { view } = match?.params;

  return {
    undefined: (
      <Login
        match={match}
        history={history}
        login={login}
        doLogin={doLogin}
        doLoginReset={doLoginReset}
      />
    ),
    login: (
      <Login
        history={history}
        login={login}
        doLogin={doLogin}
        doLoginReset={doLoginReset}
      />
    ),
    signup: <Register history={history} register={register} doRegister={doRegister} doRegisterReset={doRegisterReset} />,
    forgotpassword: <ForgotPassword history={history} forgotPassword={forgotPassword} doForgotPassword={doForgotPassword} doForgotPasswordReset={doForgotPasswordReset} />,
    activation: (
      <Activation
        match={match}
        history={history}
        activation={activation}
        doActivationReset={doActivationReset}
      />
    ),
    passwordreset: <PasswordReset match={match} history={history} passwordReset={passwordReset} doPasswordReset={doPasswordReset} doPasswordResetReset={doPasswordResetReset} />,
  }[view];


};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({
  token: state.authReducer.token,
  login: state.authReducer.login,
  register: state.authReducer.register,
  forgotPassword: state.authReducer.forgotPassword,
  passwordReset: state.authReducer.passwordReset,
  activation: state.authReducer.activation,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
      doPasswordReset,
      doForgotPassword,
      doLoginReset,
      doForgotPasswordReset,
      doActivationReset,
      doPasswordResetReset,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
AuthContainer.defaultProps = {
  view: '',
};

AuthContainer.propTypes = {
  doLogin: PropTypes.func,
  doRegister: PropTypes.func,
  doPasswordReset: PropTypes.func,
  doForgotPassword: PropTypes.func,
  doActivationReset: PropTypes.func,
  doLoginReset: PropTypes.func,
  doRegisterReset: PropTypes.func,
  doForgotPasswordReset: PropTypes.func,
  doPasswordResetReset: PropTypes.func,

  login: PropTypes.object,
  register: PropTypes.object,
  forgotPassword: PropTypes.object,
  activation: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  view: PropTypes.string,
};

// EXPORTS
// --------------------------------------------------------
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
