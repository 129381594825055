// Imports
import {
    READ_DAYNNIGHT_REVENUE_REQUEST,
    READ_DAYNNIGHT_REVENUE_SUCCESS,
    READ_DAYNNIGHT_REVENUE_FAILURE,
    READ_SHAHVENTURESLLC_REVENUE_REQUEST,
    READ_SHAHVENTURESLLC_REVENUE_SUCCESS,
    READ_SHAHVENTURESLLC_REVENUE_FAILURE,
    READ_UNOVENTURESCO_REVENUE_REQUEST,
    READ_UNOVENTURESCO_REVENUE_SUCCESS,
    READ_UNOVENTURESCO_REVENUE_FAILURE,
    READ_SOLOVENTURESCO_REVENUE_REQUEST,
    READ_SOLOVENTURESCO_REVENUE_SUCCESS,
    READ_SOLOVENTURESCO_REVENUE_FAILURE,
    READ_VIBEZVENTURESLLC_REVENUE_REQUEST,
    READ_VIBEZVENTURESLLC_REVENUE_SUCCESS,
    READ_VIBEZVENTURESLLC_REVENUE_FAILURE,
    READ_ODOO_UTAH_REVENUE_REQUEST,
    READ_ODOO_UTAH_REVENUE_SUCCESS,
    READ_ODOO_UTAH_REVENUE_FAILURE,
    READ_ODOO_COLORADO_REVENUE_REQUEST,
    READ_ODOO_COLORADO_REVENUE_SUCCESS,
    READ_ODOO_COLORADO_REVENUE_FAILURE,
    READ_ODOO_OKLAHOMA_REVENUE_REQUEST,
    READ_ODOO_OKLAHOMA_REVENUE_SUCCESS,
    READ_ODOO_OKLAHOMA_REVENUE_FAILURE,
    READ_REVENUE_SUCCESS,
    READ_REVENUE_FAILURE,
    READ_REVENUE_REQUEST,
    READ_REVENUE_FILTER_FAILURE,
    READ_REVENUE_FILTER_SUCCESS,
    READ_REVENUE_FILTER_REQUEST, CLEAR_REVENUE,
} from './types';

// Initial state
const initialState = {
    daynnightRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    shahventuresllcRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    unoventurescoRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    soloventurescoRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    vibezventuresllcRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    odooUtahRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    odooColoradoRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    odooOklahomaRevenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    revenue: {
        fetching: false,
        errors: null,
        data: null,
    },
    revenueFilter: {
        fetching: false,
        errors: null,
        data: null,
    },
};

// Reducer
const revenueReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_DAYNNIGHT_REVENUE_REQUEST:
            return {
                ...state,
                daynnightRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_DAYNNIGHT_REVENUE_SUCCESS:
            return {
                ...state,
                daynnightRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_DAYNNIGHT_REVENUE_FAILURE:
            return {
                ...state,
                daynnightRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_SHAHVENTURESLLC_REVENUE_REQUEST:
            return {
                ...state,
                shahventuresllcRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_SHAHVENTURESLLC_REVENUE_SUCCESS:
            return {
                ...state,
                shahventuresllcRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_SHAHVENTURESLLC_REVENUE_FAILURE:
            return {
                ...state,
                shahventuresllcRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_UNOVENTURESCO_REVENUE_REQUEST:
            return {
                ...state,
                unoventurescoRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_UNOVENTURESCO_REVENUE_SUCCESS:
            return {
                ...state,
                unoventurescoRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_UNOVENTURESCO_REVENUE_FAILURE:
            return {
                ...state,
                unoventurescoRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_SOLOVENTURESCO_REVENUE_REQUEST:
            return {
                ...state,
                soloventurescoRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_SOLOVENTURESCO_REVENUE_SUCCESS:
            return {
                ...state,
                soloventurescoRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_SOLOVENTURESCO_REVENUE_FAILURE:
            return {
                ...state,
                soloventurescoRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_VIBEZVENTURESLLC_REVENUE_REQUEST:
            return {
                ...state,
                vibezventuresllcRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_VIBEZVENTURESLLC_REVENUE_SUCCESS:
            return {
                ...state,
                vibezventuresllcRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_VIBEZVENTURESLLC_REVENUE_FAILURE:
            return {
                ...state,
                vibezventuresllcRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_ODOO_UTAH_REVENUE_REQUEST:
            return {
                ...state,
                odooUtahRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_ODOO_UTAH_REVENUE_SUCCESS:
            return {
                ...state,
                odooUtahRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_ODOO_UTAH_REVENUE_FAILURE:
            return {
                ...state,
                odooUtahRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_ODOO_COLORADO_REVENUE_REQUEST:
            return {
                ...state,
                odooColoradoRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_ODOO_COLORADO_REVENUE_SUCCESS:
            return {
                ...state,
                odooColoradoRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_ODOO_COLORADO_REVENUE_FAILURE:
            return {
                ...state,
                odooColoradoRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_ODOO_OKLAHOMA_REVENUE_REQUEST:
            return {
                ...state,
                odooOklahomaRevenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_ODOO_OKLAHOMA_REVENUE_SUCCESS:
            return {
                ...state,
                odooOklahomaRevenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_ODOO_OKLAHOMA_REVENUE_FAILURE:
            return {
                ...state,
                odooOklahomaRevenue: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };
        case READ_REVENUE_REQUEST:
            return {
                ...state,
                revenue: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_REVENUE_SUCCESS:
            return {
                ...state,
                revenue: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case READ_REVENUE_FAILURE:
            return {
                ...state,
                revenue: {
                    fetching: false,
                    errors: {error: "ERROR"},
                    data: null,
                },
            };
        case READ_REVENUE_FILTER_REQUEST:
            return {
                ...state,
                revenueFilter: {
                    fetching: true,
                    errors: null,
                    data: null,
                },
            };
        case READ_REVENUE_FILTER_SUCCESS:
            return {
                ...state,
                revenueFilter: {
                    fetching: false,
                    errors: null,
                    data: action.data,
                },
            };
        case CLEAR_REVENUE:
            return {
                ...state,
                revenue: {
                    fetching: false,
                    errors: null,
                    data: null,
                },
                revenueFilter: {
                    fetching: false,
                    errors: null,
                    data: null,
                },
            };
        case READ_REVENUE_FILTER_FAILURE:
            return {
                ...state,
                revenueFilter: {
                    fetching: false,
                    errors: action.error,
                    data: null,
                },
            };

        default:
            return state;
    }
};

// Export
export default revenueReducer;
