// Import
import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import LoginStyles from './styles';
import CircularProgress from '@mui/material/CircularProgress';
import EasyErrors from '../../../utils/EasyErrors';
import FormUtil from '../../../utils/FormUtil';

/**
 * LOGIN VIEW
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Login = props => {
  // Props
  const { doLogin, doLoginReset, login, history } = props;

  const [loginRequest, setLoginRequest] = React.useState(false);

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const [width, height] = useWindowSize();

  const [values, setValues] = React.useState({
    amount: '',
    email: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [validation, setValidation] = React.useState({
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
    password: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    setLoginRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setLoginRequest(true);
    }
  };

  useEffect(() => {
    doLoginReset();
  }, [doLoginReset]);

  useEffect(() => {
    if (loginRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setLoginRequest(false);
      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Username is blank'));
      }
      if (values?.password === '') {
        setValidation(FormUtil.addError(validation, 'password', 'Password is blank'));
      }
      if (FormUtil.validate(validation)) {
        let email = values.email;
        let password = values.password;
        doLogin({ email, password });
      }
    }
  }, [validation, loginRequest]);

  return (
    <LoginStyles>
      <Container>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Paper style={{ padding: width < 600 ? 10 : 50, margin: 'auto', maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: width < 600 ? 10 : 50 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0, fontSize: 50 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{width: `100%`}} src={`/logo.png`}/>

              </div>
              <div>{EasyErrors.render(login?.errors)}</div>
              <form style={{ flexGrow: 1 }} noValidate autoComplete="off">
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'email')}
                    helperText={FormUtil.renderHelperText(validation, 'email')}
                    id="standard-basic"
                    label="Username"
                    value={values.email}
                    onChange={handleChange('email')}
                    placeholder={`Username`}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'password')}
                    helperText={FormUtil.renderHelperText(validation, 'password')}
                    id="standard-adornment-password"
                    variant="outlined"
                    placeholder={`Password`}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    onKeyPress={handleKeyPress}
                    endadornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <LoadingButton
                    onClick={onSubmitForm}
                    loading={login?.fetching}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    disabled={login?.fetching}
                    size={`large`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                  >
                    Login
                  </LoadingButton>
                </FormGroup>
              </form>
            </div>
          </Paper>
        </Grid>
      </Container>
    </LoginStyles>
  );
};

// Property Types
Login.propTypes = {
  doLogin: PropTypes.func,
  doLoginReset: PropTypes.func,
  login: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
};

// Export
export default Login;
