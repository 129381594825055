// Imports
import { takeLatest, put, call } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

// Types
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  AUTH_ME_SUCCESS,
  ME_FAILURE,
  ME_REQUEST,
  ME_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  ACTIVATION_REQUEST,
  ACTIVATION_FAILURE,
  ACTIVATION_SUCCESS,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_REQUEST,
  IMPERSONATE_FAILURE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
  UNSUBSCRIBE_REQUEST,
} from './types';

// Actions
import { passwordReset, login, logout, me, forgotPassword, activation } from './actions';

/**
 * Function that handles the login http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLogin({ email, password }) {
  try {
    const response = yield call(login, { email, password });
    const { token, data } = response.data;
    yield put({ type: LOGIN_SUCCESS, token, user: data });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LOGIN_FAILURE, ...message });
  }
}

// Exports
export function* watcherLogin() {
  yield takeLatest(LOGIN_REQUEST, workerLogin);
}

/**
 * Function that handles the auth me http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerMe({ token }) {
  try {
    const response = yield call(me, { token });
    const { data } = response.data;
    yield put({ type: ME_SUCCESS, user: data });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: ME_FAILURE, ...message });
  }
}

export function* watcherMe() {
  yield takeLatest(ME_REQUEST, workerMe);
}

/**
 * Function that handles the logout http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLogout({ token, redirect }) {
  try {
    yield call(logout, { token, redirect });
    yield put({ type: LOGOUT_SUCCESS, redirect });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LOGOUT_FAILURE, ...message, redirect });
  }
}

export function* watcherLogout() {
  yield takeLatest(LOGOUT_REQUEST, workerLogout);
}

function* workerPasswordReset({ password, password_confirmation, token, validator }) {
  try {
    const response = yield call(passwordReset, { password, password_confirmation, token, validator });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerPasswordReset - Response Error');
    }

    yield put({
      type: PASSWORD_RESET_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: PASSWORD_RESET_FAILURE, ...message });
  }
}

// Exports
export function* watcherPasswordReset() {
  yield takeLatest(PASSWORD_RESET_REQUEST, workerPasswordReset);
}

function* workerForgotPassword({ email }) {
  try {
    const response = yield call(forgotPassword, { email });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerForgotPassword - Response Error');
    }

    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: FORGOT_PASSWORD_FAILURE, ...message });
  }
}

// Exports
export function* watcherForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword);
}
