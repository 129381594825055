// IMPORTS
import {all, fork} from 'redux-saga/effects';
import {
    watcherLogin,
    watcherLogout,
    watcherMe,
    watcherForgotPassword,
    watcherPasswordReset,
} from '../containers/Auth/sagas';
import {
    watcherGetUsers,
    watcherGetUser,
    watcherCreateUser,
    watcherUpdateUser,
    watcherDeleteUser,
    watcherUpdateDeleteUser
} from '../containers/User/sagas';
import {watcherCreateAsset, watcherDeleteAsset, watcherGetAsset, watcherGetAssets} from '../containers/Asset/sagas';
import {
    watcherGetCOReportInformation,
    watcherGetDAYNNIGHTTotalRevenue,
    watcherGetOKReportInformation, watcherGetRevenue, watcherGetRevenueFilter,
    watcherGetSHAHVENTURESLLCTotalRevenue,
    watcherGetSOLOVENTURESCOTotalRevenue,
    watcherGetUNOVENTURESCOTotalRevenue,
    watcherGetUTReportInformation, watcherGetVIBEZVENTURESLLCTotalRevenue
} from "../containers/Revenue/sagas";
// SAGAS
// MAIN ROOT SAGA
/**
 * Main root saga
 * @returns {void}
 */
function* rootSaga() {
    yield all(
        [
            watcherLogin,
            watcherMe,
            watcherLogout,

            watcherForgotPassword,
            watcherPasswordReset,

            // Users
            watcherGetUsers,
            watcherGetUser,
            watcherCreateUser,
            watcherUpdateUser,
            watcherUpdateDeleteUser,
            watcherDeleteUser,

            // Assets
            watcherGetAssets,
            watcherGetAsset,
            watcherCreateAsset,
            watcherDeleteAsset,

            watcherGetCOReportInformation,
            watcherGetUTReportInformation,
            watcherGetOKReportInformation,

            watcherGetDAYNNIGHTTotalRevenue,
            watcherGetSHAHVENTURESLLCTotalRevenue,
            watcherGetSOLOVENTURESCOTotalRevenue,
            watcherGetUNOVENTURESCOTotalRevenue,
            watcherGetVIBEZVENTURESLLCTotalRevenue,

            watcherGetRevenue,
            watcherGetRevenueFilter
        ].map(fork),
    );
}

// EXPORTS
export default rootSaga;
