// Imports

// action types
import {
  LIST_USER_REQUEST,
  LIST_USER_SUCCESS,
  LIST_USER_FAILURE,
  READ_USER_REQUEST,
  READ_USER_SUCCESS,
  READ_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CLEAR_USER,
  UPDATE_DELETE_USER_REQUEST,
  UPDATE_DELETE_USER_FAILURE,
  UPDATE_DELETE_USER_SUCCESS,
  CLEAR_UPDATE_USER,
} from './types';

// reducer with initial state
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  updateDelete: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

// User reducer
/**
 * Main user reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_USER_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_USER_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_USER_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_USER_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_USER_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_DELETE_USER_SUCCESS:
      return {
        ...state,
        updateDelete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_DELETE_USER_FAILURE:
      return {
        ...state,
        updateDelete: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case UPDATE_DELETE_USER_REQUEST:
      return {
        ...state,
        updateDelete: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CLEAR_UPDATE_USER:
      return {
        ...state,
        updateDelete: {
          fetching: true,
          errors: null,
          data: null,
        },
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
};

// Exports
export default userReducer;
