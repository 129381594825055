// IMPORTS
import { combineReducers } from 'redux';

// REDUCERS
import appReducer from '../containers/App/reducers';
import authReducer from '../containers/Auth/reducers';
import userReducer from '../containers/User/reducers';
import assetReducer from '../containers/Asset/reducers';
import revenueReducer from "../containers/Revenue/reducers";

// MAIN ROOT REDUCER
/**
 * Main root reducer
 * @param {Object} history redux history
 * @returns {Reducer<CombinedState<unknown>>} all reducers in one main root reducer
 */

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  userReducer,
  assetReducer,
  revenueReducer,
});

// EXPORTS
export default rootReducer;
