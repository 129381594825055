// Import types

// Export operations
import {
    READ_DAYNNIGHT_REVENUE_REQUEST,
    READ_ODOO_COLORADO_REVENUE_REQUEST,
    READ_ODOO_OKLAHOMA_REVENUE_REQUEST,
    READ_ODOO_UTAH_REVENUE_REQUEST, READ_REVENUE_FILTER_REQUEST, READ_REVENUE_REQUEST,
    READ_SHAHVENTURESLLC_REVENUE_REQUEST,
    READ_SOLOVENTURESCO_REVENUE_REQUEST,
    READ_UNOVENTURESCO_REVENUE_REQUEST,
    READ_VIBEZVENTURESLLC_REVENUE_REQUEST,
    CLEAR_REVENUE
} from "./types";

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetDAYNNIGHTTotalRevenue = ({token}) => ({
    type: READ_DAYNNIGHT_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetSHAHVENTURESLLCTotalRevenue = ({token}) => ({
    type: READ_SHAHVENTURESLLC_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetUNOVENTURESCOTotalRevenue = ({token}) => ({
    type: READ_UNOVENTURESCO_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetSOLOVENTURESCOTotalRevenue = ({token}) => ({
    type: READ_SOLOVENTURESCO_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetVIBEZVENTURESLLCTotalRevenue = ({token}) => ({
    type: READ_VIBEZVENTURESLLC_REVENUE_REQUEST,
    token,
});


/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetUTReportInformation = ({token}) => ({
    type: READ_ODOO_UTAH_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetCOReportInformation = ({token}) => ({
    type: READ_ODOO_COLORADO_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting specific revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetOKReportInformation = ({token}) => ({
    type: READ_ODOO_OKLAHOMA_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for getting all revenue
 * @param {Object} { token: string }
 * @return {Object} Dispatch
 */
export const doGetRevenue = ({token}) => ({
    type: READ_REVENUE_REQUEST,
    token,
});

/**
 * Dispatch for revenue filter
 * @param {Object} { token: string, date_start: string, date_end: string }
 * @return {Object} Dispatch
 */
export const doGetRevenueFilter = ({token, payload}) => ({
    type: READ_REVENUE_FILTER_REQUEST,
    token,
    payload,
});

/**
 * Dispatch user to initial state
 * @return {Object} Dispatch
 */
export const doClearRevenue = () => ({
    type: CLEAR_REVENUE,
});
