//IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//REDUX
import store from '../redux/store';

//CONTAINERS
import AppContainer from '../containers/App';
import Auth from '../containers/Auth';
import Portal from '../containers/Portal';

//UTILS
import NetworkService from '../utils/NetworkService';
import ErrorRoute from './error';

import { createHashHistory, createBrowserHistory } from 'history';

import { Router } from 'react-router-dom';

// Config
// var history;
// Configure History
// export const history = createBrowserHistory();
//if (window?.clientInformation && window?.clientInformation?.platform && window?.clientInformation?.platform === 'iPhone') {
// var history = createHashHistory();
//} else {
var history = createBrowserHistory();
// var history = createHashHistory();
//}

//INTERCEPTORS
NetworkService.setupInterceptors({ store, history });

//MAIN COMPONENT
/**
 * Main route component
 * @param {*} props default component props
 * @returns {JSX.Element} react component
 */
const RoutesContainer = props => {
  // State / Props
  const { user, activation } = props;
  return (
    <Router history={history}>
      <AppContainer>
        {/* PUBLIC ROUTES */}
        {activation?.data || !user ? (
          <>
            <Switch>
              <Route path="/auth/:view/:type/:token/:validator" component={Auth} />
              <Route path="/auth/:view/:token/:validator" component={Auth} />
              <Route path="/auth/:view/:email" component={Auth} />
              <Route path="/auth/:view" component={Auth} />

              <Route path="/" component={Auth} />
              {/*<ErrorRoute path="*" status={404} />*/}
            </Switch>
          </>
        ) : user && user?.role?.name === 'admin' ? (
          <Switch>
            {/*<Route exact path="/" component={Catalog} />*/}
            <Route
              exact
              path="/auth/:view"
              render={() => {
                return user ? <Redirect to="/" /> : <Auth />;
              }}
            />
            <ErrorRoute path="*" status={404} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/auth/:view" render={Auth} />
            <Route path="/auth/:view/:type/:token/:validator" component={Auth} />
              <Route exact path="*" component={Portal} />
            <ErrorRoute path="*" status={404} />
          </Switch>
        )}
      </AppContainer>
    </Router>
  );
};

//REDUX
/**
 * Maps redux state to local component state
 * @param {*} state incoming redux state
 * @return {Object} redux state
 */
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  user: state.authReducer.user,
  activation: state.authReducer.activation,
});

/**
 * Maps redux actions to local component state
 * @param {*} dispatch incoming redux dispatch
 * @return {Object} redux actions
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

RoutesContainer.propTypes = {
  user: PropTypes.object,
  activation: PropTypes.object,
};

//EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(RoutesContainer);

export { history };
