export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const ME_FAILURE = 'ME_FAILURE';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_REQUEST = 'ME_REQUEST';
export const AUTH_ME_SUCCESS = 'AUTH_ME_SUCCESS';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
export const PASSWORD_RESET_RESET = 'PASSWORD_RESET_RESET';
export const ACTIVATION_RESET = 'ACTIVATION_RESET';
