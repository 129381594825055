// Imports
import axios from 'axios';

/**
 * Get Revenue for DayNNight
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getDAYNNIGHTTotalRevenue = ({ token}) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/revenue/ls/daynnight`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get Revenue for shahventuresllc
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getSHAHVENTURESLLCTotalRevenue = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/ls/shahventuresllc`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get Revenue for unoventuresco
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getUNOVENTURESCOTotalRevenue = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/ls/unoventuresco`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get Revenue for soloventuresco
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getSOLOVENTURESCOTotalRevenue = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/ls/soloventuresco`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get Revenue for vibezventuresllc
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getVIBEZVENTURESLLCTotalRevenue = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/ls/vibezventuresllc`,
        headers: { Authorization: `Bearer ${token}` },
    });
};


/**
 * Get Revenue for odoo utah
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getUTReportInformation = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/odoo/utah`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get Revenue for odoo colorado
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getCOReportInformation = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/odoo/colorado`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get Revenue for odoo oklahoma
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getOKReportInformation = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue/odoo/oklahoma`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get all revenue
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getRevenue = ({ token}) => {
    return axios({
        method: 'get',
        url: `${window.API_URL}/revenue`,
        headers: { Authorization: `Bearer ${token}` },
    });
};

/**
 * Get all revenue
 * @param {Object} { token: string}
 * @return {AxiosPromise} Http request
 */
export const getRevenueFilter = ({ token, payload}) => {
    return axios({
        method: 'post',
        url: `${window.API_URL}/revenuefilter`,
        data: payload,
        headers: { Authorization: `Bearer ${token}` },
    });
};
