// Imports
import {takeLatest, put, call} from 'redux-saga/effects';
import {
    READ_DAYNNIGHT_REVENUE_REQUEST,
    READ_DAYNNIGHT_REVENUE_SUCCESS,
    READ_DAYNNIGHT_REVENUE_FAILURE,
    READ_SHAHVENTURESLLC_REVENUE_REQUEST,
    READ_SHAHVENTURESLLC_REVENUE_SUCCESS,
    READ_SHAHVENTURESLLC_REVENUE_FAILURE,
    READ_UNOVENTURESCO_REVENUE_REQUEST,
    READ_UNOVENTURESCO_REVENUE_SUCCESS,
    READ_UNOVENTURESCO_REVENUE_FAILURE,
    READ_SOLOVENTURESCO_REVENUE_REQUEST,
    READ_SOLOVENTURESCO_REVENUE_SUCCESS,
    READ_SOLOVENTURESCO_REVENUE_FAILURE,
    READ_VIBEZVENTURESLLC_REVENUE_REQUEST,
    READ_VIBEZVENTURESLLC_REVENUE_SUCCESS,
    READ_VIBEZVENTURESLLC_REVENUE_FAILURE,
    READ_ODOO_UTAH_REVENUE_REQUEST,
    READ_ODOO_UTAH_REVENUE_SUCCESS,
    READ_ODOO_UTAH_REVENUE_FAILURE,
    READ_ODOO_COLORADO_REVENUE_REQUEST,
    READ_ODOO_COLORADO_REVENUE_SUCCESS,
    READ_ODOO_COLORADO_REVENUE_FAILURE,
    READ_ODOO_OKLAHOMA_REVENUE_REQUEST,
    READ_ODOO_OKLAHOMA_REVENUE_SUCCESS,
    READ_ODOO_OKLAHOMA_REVENUE_FAILURE,
    READ_REVENUE_REQUEST,
    READ_REVENUE_SUCCESS,
    READ_REVENUE_FAILURE,
    READ_REVENUE_FILTER_FAILURE,
    READ_REVENUE_FILTER_SUCCESS,
    READ_REVENUE_FILTER_REQUEST,
} from './types';

// Actions
import {
    getDAYNNIGHTTotalRevenue,
    getSHAHVENTURESLLCTotalRevenue,
    getUNOVENTURESCOTotalRevenue,
    getSOLOVENTURESCOTotalRevenue,
    getVIBEZVENTURESLLCTotalRevenue,
    getUTReportInformation,
    getOKReportInformation,
    getCOReportInformation,
    getRevenue, getRevenueFilter
} from './actions';

// DAYNNIGHT Revenue Sagas
function* workerGetDAYNNIGHTTotalRevenue({token}) {
    try {
        const response = yield call(getDAYNNIGHTTotalRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_DAYNNIGHT_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_DAYNNIGHT_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetDAYNNIGHTTotalRevenue() {
    yield takeLatest(READ_DAYNNIGHT_REVENUE_REQUEST, workerGetDAYNNIGHTTotalRevenue);
}

// SHAHVENTURESLLC Revenue Sagas
function* workerGetSHAHVENTURESLLCTotalRevenue({token}) {
    try {
        const response = yield call(getSHAHVENTURESLLCTotalRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_SHAHVENTURESLLC_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_SHAHVENTURESLLC_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetSHAHVENTURESLLCTotalRevenue() {
    yield takeLatest(READ_SHAHVENTURESLLC_REVENUE_REQUEST, workerGetSHAHVENTURESLLCTotalRevenue);
}

// UNOVENTURESCO Revenue Sagas
function* workerGetUNOVENTURESCOTotalRevenue({token}) {
    try {
        const response = yield call(getUNOVENTURESCOTotalRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_UNOVENTURESCO_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_UNOVENTURESCO_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetUNOVENTURESCOTotalRevenue() {
    yield takeLatest(READ_UNOVENTURESCO_REVENUE_REQUEST, workerGetUNOVENTURESCOTotalRevenue);
}

// SOLOVENTURESCO Revenue Sagas
function* workerGetSOLOVENTURESCOTotalRevenue({token}) {
    try {
        const response = yield call(getSOLOVENTURESCOTotalRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_SOLOVENTURESCO_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_SOLOVENTURESCO_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetSOLOVENTURESCOTotalRevenue() {
    yield takeLatest(READ_SOLOVENTURESCO_REVENUE_REQUEST, workerGetSOLOVENTURESCOTotalRevenue);
}

// VIBEZVENTURESLLC Revenue Sagas
function* workerGetVIBEZVENTURESLLCTotalRevenue({token}) {
    try {
        const response = yield call(getVIBEZVENTURESLLCTotalRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_VIBEZVENTURESLLC_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_VIBEZVENTURESLLC_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetVIBEZVENTURESLLCTotalRevenue() {
    yield takeLatest(READ_VIBEZVENTURESLLC_REVENUE_REQUEST, workerGetVIBEZVENTURESLLCTotalRevenue);
}


// ODOO UTAH Revenue Sagas
function* workerGetUTReportInformation({token}) {
    try {
        const response = yield call(getUTReportInformation, {token});
        const {data} = response.data;
        yield put({type: READ_ODOO_UTAH_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_ODOO_UTAH_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetUTReportInformation() {
    yield takeLatest(READ_ODOO_UTAH_REVENUE_REQUEST, workerGetUTReportInformation);
}

// ODOO COLORADO Revenue Sagas
function* workerGetCOReportInformation({token}) {
    try {
        const response = yield call(getCOReportInformation, {token});
        const {data} = response.data;
        yield put({type: READ_ODOO_COLORADO_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_ODOO_COLORADO_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetCOReportInformation() {
    yield takeLatest(READ_ODOO_COLORADO_REVENUE_REQUEST, workerGetCOReportInformation);
}

// ODOO OKLAHOMA Revenue Sagas
function* workerGetOKReportInformation({token}) {
    try {
        const response = yield call(getOKReportInformation, {token});
        const {data} = response.data;
        yield put({type: READ_ODOO_OKLAHOMA_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_ODOO_OKLAHOMA_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetOKReportInformation() {
    yield takeLatest(READ_ODOO_OKLAHOMA_REVENUE_REQUEST, workerGetOKReportInformation);
}

//  Revenue Sagas
function* workerGetRevenue({token}) {
    try {
        const response = yield call(getRevenue, {token});
        const {data} = response.data;
        yield put({type: READ_REVENUE_SUCCESS, data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : null;
        yield put({type: READ_REVENUE_FAILURE, error: message});
    }
}

export function* watcherGetRevenue() {
    yield takeLatest(READ_REVENUE_REQUEST, workerGetRevenue);
}


//  Revenue Sagas
function* workerGetRevenueFilter({token, payload}) {
    try {
        const response = yield call(getRevenueFilter, {token, payload});
        const {data} = response.data;
        yield put({type: READ_REVENUE_FILTER_SUCCESS, data});
    } catch (error) {
        yield put({type: READ_REVENUE_FILTER_FAILURE});
    }
}

export function* watcherGetRevenueFilter() {
    yield takeLatest(READ_REVENUE_FILTER_REQUEST, workerGetRevenueFilter);
}
