import React, { useEffect, useLayoutEffect, useState } from 'react';
import PortalStyles from "./styles";
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { ListItem, List, Typography, Menu, ListItemIcon, Grid, Card, CardContent, LinearProgress, IconButton, TextField, Button, CircularProgress } from "@mui/material";
import FormatUtil from "../../utils/FormatUtil";
import Toolbar from '@mui/material/Toolbar';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import {doClearUser} from "../../containers/User/operations";

const titleMap = {
    daynnight: 'Day N Night',
    shahventuresllc: 'Shah Ventures LLC',
    unoventuresco: 'Uno Ventures CO',
    soloventuresco: 'Solo Ventures CO',
    vibezventuresllc: 'Vibez Ventures LLC',
    odooUtah: 'Odoo Utah',
    odooColorado: 'Odoo Colorado',
    odooOklahoma: 'Odoo Oklahoma'
};

const Portal = (props) => {
    const {
        user, token, history, doLogout,
        revenue,
        revenueFilter,
        doGetRevenue,
        doGetRevenueFilter,
        doClearUser,
        doClearRevenue
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedType, setSelectedType] = useState('day');
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState(null);

    const useWindowSize = () => {
        const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    };

    const [width, height] = useWindowSize();
    const isMobile = width < 600;

    const handleClick = (event) => {
        if (anchorEl != null) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        event.preventDefault();
        if (doLogout) {
            doLogout({ token, redirect: null });

        }
        if(doClearRevenue) {
            doClearRevenue();
        }

        history.push('/');
    };

    const handleTypeClick = (type) => {
        setFilteredData(null);
        setSelectedType(type);
    };

    const handleFilterClick = () => {
        doGetRevenueFilter({ token, payload: { start_date: startDate, end_date: endDate } });
        setLoading(true);
    };

    useEffect(() => {
        if (!revenue?.fetching && !revenue?.data && !revenue?.errors) {
            doGetRevenue({ token });
        } else if (revenue?.data) {
            setLoading(false);
        } else if(revenue?.errors) {
            doClearUser();
            doClearRevenue();
        }
    }, [revenue.fetching, revenue.data, token, doClearUser, doClearRevenue]);

    useEffect(() => {
        if (revenueFilter?.data) {
            setLoading(false);
            setFilteredData(revenueFilter.data);
        }
    }, [revenueFilter]);

    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/[^\d.-]/g, ''));
        }
        return value;
    };

    const formatMoney = (value) => {
        return value?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
    };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) return 'N/A';
        return (((current - previous) / previous) * 100).toFixed(2);
    };

    const renderRevenueContent = (title, revenueData) => {
        const periods = [
            { label: 'Today', key: 'today', previousKey: 'yesterday', type: 'day' },
            { label: 'Week', key: 'this_week', previousKey: 'last_week', type: 'week' },
            { label: 'Month', key: 'this_month', previousKey: 'last_month', type: 'month' },
            { label: 'Quarter', key: 'this_quarter', previousKey: 'last_quarter', type: 'quarter' },
        ];

        return (
            <div className="period-labels">
                {periods.map((period, index) => {
                    const currentValue = convertToNumber(revenueData[period.key]);
                    const previousValue = convertToNumber(revenueData[period.previousKey]);
                    const percentageChange = calculatePercentageChange(currentValue, previousValue);
                    const isIncrease = percentageChange >= 0;
                    const isSelected = selectedType === period.type;

                    return (
                        <div key={index} className={`revenue-period ${isSelected ? 'selected-period' : ''}`}>
                            <Typography
                                variant="body1"
                                className={`period-label ${isSelected ? 'bold' : ''}`}
                                align="center"
                            >
                                <div>{period.label}  {isIncrease ? '▲' : '▼'}</div>
                                <div className={`percentage-change ${isIncrease ? 'increase' : 'decrease'}`}>
                                    {Math.abs(percentageChange)}%
                                </div>
                            </Typography>
                        </div>
                    );
                })}
            </div>
        );
    };

    const aggregateRevenueData = (revenueData) => {
        const aggregatedData = {
            today: 0,
            yesterday: 0,
            this_week: 0,
            last_week: 0,
            this_month: 0,
            last_month: 0,
            this_quarter: 0,
            last_quarter: 0,
        };

        Object.values(revenueData).forEach(data => {
            Object.keys(aggregatedData).forEach(key => {
                aggregatedData[key] += convertToNumber(data[key]);
            });
        });

        return aggregatedData;
    };

    const aggregateFilteredData = (filteredData) => {
        const aggregatedData = {
            daynnight: 0,
            shahventuresllc: 0,
            unoventuresco: 0,
            soloventuresco: 0,
            vibezventuresllc: 0,
            odooUtah: 0,
            odooColorado: 0,
            odooOklahoma: 0
        };

        Object.keys(filteredData).forEach(key => {
            aggregatedData[key] += convertToNumber(filteredData[key]);
        });

        return Object.values(aggregatedData).reduce((acc, value) => acc + value, 0);
    };

    const RevenueCard = ({ title, revenueData, filterMode }) => {
        if (filterMode) {
            return (
                <Card className="revenue-card" >
                    <CardContent style={{textAlign: `center`}}>
                        <Typography variant="h5" className="card-title">{titleMap[title] || title}</Typography>
                        <Typography variant="h6" style={{textAlign: `center`}}>
                            Revenue: {formatMoney(convertToNumber(revenueData))}
                        </Typography>
                        <Typography variant="body2" className="progress-text">
                            {`From ${startDate} to ${endDate}`}
                        </Typography>
                    </CardContent>
                </Card>
            );
        }

        const selectedPeriod = revenueData[selectedType === 'day' ? 'today' :
            selectedType === 'week' ? 'this_week' :
                selectedType === 'month' ? 'this_month' : 'this_quarter'];
        const previousPeriod = revenueData[selectedType === 'day' ? 'yesterday' :
            selectedType === 'week' ? 'last_week' :
                selectedType === 'month' ? 'last_month' : 'last_quarter'];
        const percentageChange = calculatePercentageChange(convertToNumber(selectedPeriod), convertToNumber(previousPeriod));
        const isIncrease = percentageChange >= 0;

        return (
            <Card className="revenue-card">
                <CardContent>
                    <Typography variant="h5" className="card-title">{titleMap[title] || title}</Typography>
                    <>
                        {renderRevenueContent(title, revenueData)}
                        <Typography variant="h6"style={{textAlign: `center`,fontWeight:`bold`}} >
                            {`${selectedType === 'day' ? 'Today' :
                                selectedType === 'week' ? 'This Week' :
                                    selectedType === 'month' ? 'This Month' : 'This Quarter'}: ${formatMoney(convertToNumber(selectedPeriod))}`}
                            <span className={`percentage-change ${isIncrease ? 'increase' : 'decrease'}`}>
                                {isIncrease ? '▲' : '▼'} {Math.abs(percentageChange)}%
                            </span>
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={(convertToNumber(selectedPeriod) / (convertToNumber(previousPeriod) || 1)) * 100}
                            className={`progress-bar ${isIncrease ? 'increase' : 'decrease'}`}
                        />
                        <Typography variant="body2" className="progress-text" style={{textAlign: `center`}} >
                            {`${selectedType === 'day' ? 'Yesterday' :
                                selectedType === 'week' ? 'Last Week' :
                                    selectedType === 'month' ? 'Last Month' : 'Last Quarter'}: ${formatMoney(convertToNumber(previousPeriod))}`}
                        </Typography>
                    </>
                </CardContent>
            </Card>
        );
    };

    const overallRevenueData = revenue.data ? aggregateRevenueData(revenue.data) : {};
    const overallFilteredRevenue = filteredData ? aggregateFilteredData(filteredData) : null;

    return (
        <PortalStyles>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position={'relative'}
                    className={`dashboard-navbar`}
                    style={{
                        backgroundColor: 'rgb(29, 29, 31)',
                        boxShadow: 0,
                        zIndex: 99999,
                        height: 50,
                        position: `relative`,
                    }}
                >
                    <Container disableGutters={true}>
                        <Toolbar disableGutters={true} style={{ minHeight: 50 }}>
                            <List
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: 0,
                                    height: `100%`,
                                }}
                            >
                                <ListItem style={{ maxWidth: 200, width: `auto`, paddingTop: 0, paddingBottom: 0 }}>
                                    <IconButton edge="start" color="inherit" size={width < 600 ? 'small' : 'medium'}
                                                onClick={handleClick}>
                                        <PersonIcon fontSize={width < 600 ? 'small' : 'medium'}
                                                    style={{ marginRight: 5 }} />
                                        <Typography variant="subtitle2" style={{ fontSize: width < 600 ? 12 : 16 }}>
                                            {FormatUtil.nameShrinker(user?.name)}
                                        </Typography>
                                        {anchorEl ? <KeyboardArrowUpIcon fontSize={`small`} /> :
                                            <KeyboardArrowDownIcon fontSize={`small`} />}
                                    </IconButton>
                                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
                                          style={{ zIndex: 9999 }} onClose={handleClose}>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                </ListItem>
                            </List>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Container style={{ marginTop: '20px' }}>
                    <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Button
                                variant={selectedType === 'day' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleTypeClick('day')}
                            >
                                Day
                            </Button>
                            <Button
                                variant={selectedType === 'week' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleTypeClick('week')}
                                style={{ marginLeft: 5 }}
                            >
                                Week
                            </Button>
                            <Button
                                variant={selectedType === 'month' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleTypeClick('month')}
                                style={{ marginLeft: 5 }}
                            >
                                Month
                            </Button>
                            <Button
                                variant={selectedType === 'quarter' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleTypeClick('quarter')}
                                style={{ marginLeft: 5 }}
                            >
                                Quarter
                            </Button>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ marginRight: 10 }}
                            />
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ marginRight: 10 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFilterClick}
                            >
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={3} style={{ marginTop: 0 }}>
                            {filteredData ? (
                                <>
                                    <Grid item xs={12}>
                                        <RevenueCard title="Overall" revenueData={overallFilteredRevenue} filterMode />
                                    </Grid>
                                    {Object.keys(filteredData).map((key, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <RevenueCard title={key} revenueData={filteredData[key]} filterMode />
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <RevenueCard title="Overall" revenueData={overallRevenueData} />
                                    </Grid>
                                    {revenue.data && Object.keys(revenue.data).map((key, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <RevenueCard title={key} revenueData={revenue.data[key]} />
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                    )}
                    <div style={{ marginTop: 15, height: 5 }}></div>
                </Container>
            </Box>
        </PortalStyles>
    );
}

export default Portal;
