// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export function login(loginData) {
  const payload = {};
  if (loginData.email.indexOf('@') > -1) {
    payload.email = loginData.email;
    payload.password = loginData.password;
  } else {
    payload.username = loginData.email;
    payload.password = loginData.password;
  }

  return axios({
    method: 'post',
    url: `${apiUri}/auth/login`,
    data: { ...payload },
    withCredentials: true,
  });
}

export function me({ token }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/me`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function logout({ token, redirect }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/logout`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function forgotPassword(forgotData) {
  const payload = {};
  if (forgotData.email.indexOf('@') > -1) {
    payload.email = forgotData.email;
  } else {
    payload.username = forgotData.email;
  }
  return axios({
    method: 'post',
    url: `${apiUri}/auth/forgotpassword`,
    data: { ...payload },
  });
}

export function passwordReset({ password, password_confirmation, token, validator }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/passwordreset`,
    data: { password, password_confirmation, token, validator },
  });
}