// Types for DAYNNIGHT revenue
export const READ_DAYNNIGHT_REVENUE_REQUEST = 'READ_DAYNNIGHT_REVENUE_REQUEST';
export const READ_DAYNNIGHT_REVENUE_SUCCESS = 'READ_DAYNNIGHT_REVENUE_SUCCESS';
export const READ_DAYNNIGHT_REVENUE_FAILURE = 'READ_DAYNNIGHT_REVENUE_FAILURE';

// Types for SHAHVENTURESLLC revenue
export const READ_SHAHVENTURESLLC_REVENUE_REQUEST = 'READ_SHAHVENTURESLLC_REVENUE_REQUEST';
export const READ_SHAHVENTURESLLC_REVENUE_SUCCESS = 'READ_SHAHVENTURESLLC_REVENUE_SUCCESS';
export const READ_SHAHVENTURESLLC_REVENUE_FAILURE = 'READ_SHAHVENTURESLLC_REVENUE_FAILURE';

// Types for UNOVENTURESCO revenue
export const READ_UNOVENTURESCO_REVENUE_REQUEST = 'READ_UNOVENTURESCO_REVENUE_REQUEST';
export const READ_UNOVENTURESCO_REVENUE_SUCCESS = 'READ_UNOVENTURESCO_REVENUE_SUCCESS';
export const READ_UNOVENTURESCO_REVENUE_FAILURE = 'READ_UNOVENTURESCO_REVENUE_FAILURE';

// Types for SOLOVENTURESCO revenue
export const READ_SOLOVENTURESCO_REVENUE_REQUEST = 'READ_SOLOVENTURESCO_REVENUE_REQUEST';
export const READ_SOLOVENTURESCO_REVENUE_SUCCESS = 'READ_SOLOVENTURESCO_REVENUE_SUCCESS';
export const READ_SOLOVENTURESCO_REVENUE_FAILURE = 'READ_SOLOVENTURESCO_REVENUE_FAILURE';

// Types for VIBEZVENTURESLLC revenue
export const READ_VIBEZVENTURESLLC_REVENUE_REQUEST = 'READ_VIBEZVENTURESLLC_REVENUE_REQUEST';
export const READ_VIBEZVENTURESLLC_REVENUE_SUCCESS = 'READ_VIBEZVENTURESLLC_REVENUE_SUCCESS';
export const READ_VIBEZVENTURESLLC_REVENUE_FAILURE = 'READ_VIBEZVENTURESLLC_REVENUE_FAILURE';


// Types for ODOO UTAH revenue
export const READ_ODOO_UTAH_REVENUE_REQUEST = 'READ_ODOO_UTAH_REVENUE_REQUEST';
export const READ_ODOO_UTAH_REVENUE_SUCCESS = 'READ_ODOO_UTAH_REVENUE_SUCCESS';
export const READ_ODOO_UTAH_REVENUE_FAILURE = 'READ_ODOO_UTAH_REVENUE_FAILURE';

// Types for ODOO COLORADO revenue
export const READ_ODOO_COLORADO_REVENUE_REQUEST = 'READ_ODOO_COLORADO_REVENUE_REQUEST';
export const READ_ODOO_COLORADO_REVENUE_SUCCESS = 'READ_ODOO_COLORADO_REVENUE_SUCCESS';
export const READ_ODOO_COLORADO_REVENUE_FAILURE = 'READ_ODOO_COLORADO_REVENUE_FAILURE';

// Types for ODOO OKLAHOMA revenue
export const READ_ODOO_OKLAHOMA_REVENUE_REQUEST = 'READ_ODOO_OKLAHOMA_REVENUE_REQUEST';
export const READ_ODOO_OKLAHOMA_REVENUE_SUCCESS = 'READ_ODOO_OKLAHOMA_REVENUE_SUCCESS';
export const READ_ODOO_OKLAHOMA_REVENUE_FAILURE = 'READ_ODOO_OKLAHOMA_REVENUE_FAILURE';

// Types for revenue
export const READ_REVENUE_REQUEST = 'READ_REVENUE_REQUEST';
export const READ_REVENUE_SUCCESS = 'READ_REVENUE_SUCCESS';
export const READ_REVENUE_FAILURE = 'READ_REVENUE_FAILURE';

export const READ_REVENUE_FILTER_REQUEST = 'READ_REVENUE_FILTER_REQUEST';
export const READ_REVENUE_FILTER_SUCCESS = 'READ_REVENUE_FILTER_SUCCESS';
export const READ_REVENUE_FILTER_FAILURE = 'READ_REVENUE_FILTER_FAILURE';

export const CLEAR_REVENUE = 'CLEAR_REVENUE';
